import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We’re excited to announce support for Google’s new `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/google-multimodal"
      }}>{`multimodal`}</a>{` features for `}<a parentName="p" {...{
        "href": "http://developer.google.com/actions"
      }}>{`Apps on the Google Assistant`}</a>{`.`}</p>
    <p>{`With multimodal support, developers can add cards, carousels, lists, chips and other UI/UX features for touch enabled interfaces like Google Allo and Pixel.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` was an Early Access Partner of Google to support the initial developer launch December 8th. These new features open up a lot of opportunities for developers to create engaging conversational experiences across devices.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/07/07120630/1b3bd-1nzgathr8zyadc5tykjkmmg.png)
    </figure>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/07/07120631/52b63-1_g9yudlhqrgpwu-vndwrlw.png)
    </figure>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/07/07120629/4fcd3-1l1ywmvfgtxpuagdtjrxrjq.png)
    </figure>
In addition to the new feature support, Dashbot is sponsoring one of the prize
categories announced at Google I/O: “Best Dashbot powered app”
    <p>{`Developers who build an App incorporating Dashbot are eligible to win \\$5,000 for the App with the best metrics.`}</p>
    <p>{`More information is available at `}<a parentName="p" {...{
        "href": "https://g.co/actionschallenge"
      }}>{`https://g.co/actionschallenge`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional analytics like engagement and retention, we have bot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We natively support Facebook Messenger, Alexa, the Google Assistant, Slack, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      